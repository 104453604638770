import * as React from "react"
import { useState } from "react"
import { graphql, Link } from "gatsby"
import { Layout } from "../../../components/layout"
import isEqual from "lodash.isequal"
import { StoreContext } from "../../../context/store-context"
import { AddToCart } from "../../../components/add-to-cart"
import { NumericInput } from "../../../components/numeric-input"
import { formatPrice } from "../../../utils/format-price"
import { Seo } from "../../../components/seo"
import Helmet from "react-helmet"
import { Gallery, Item } from "react-photoswipe-gallery"
import "photoswipe/dist/photoswipe.css"
import SocialShareLinks from "../../../components/SocialShareLinks"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Input } from "@/components/ui/input"

import {
  productBox,
  container,
  // header,
  productImageWrapper,
  // productImageList,
  // productImageListItem,
  // scrollForMore,
  noImagePreview,
  optionsWrapper,
  // priceValue,
  selectVariant,
  // labelFont,
  // breadcrumb,
  // tagList,
  addToCartStyle,
  // metaSection,
  // productDescription,
} from "./product-page.module.css"
// import { MdFullscreen } from "react-icons/md"

export default function Product({ data: { product, suggestions } }) {
  const [currentThumbIndex, setCurrentThumbIndex] = useState(0)

  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    descriptionHtml,
    tags,
    images,
    images: [firstImage],
  } = product
  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)
  const [focusAltText, setFocusAltText] = React.useState(firstImage.altText)
  const [variantSelected, setVariantSelected] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState(null)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant
  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const getFilteredSuggestions = (suggestions) => {
    return suggestions.nodes.reduce((acc, suggestion) => {
      // Check if any tag from `suggestion.tags` is in `tags`
      if (suggestion.tags.some((tag) => tags.includes(tag))) {
        acc.push(suggestion)
      }

      return acc
    }, [])
  }
  const filteredSuggestions = getFilteredSuggestions(suggestions)
  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === "") {
      setVariantSelected(false)
      setSelectedVariant(null)
      return
    } else {
      setVariantSelected(true)
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
    setSelectedVariant(selectedVariant) // Add this line to update selectedVariant
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1
  const hasImages = images.length > 0
  // const hasMultipleImages = true || images.length > 1

  let galleryImages = []
  images.forEach((image) => {
    galleryImages.push({
      original: image.original,
      thumbnail: image.thumbnail.images.fallback.src,
      srcSet: image.thumbnail.images.sources[0].srcSet,
      originalHeight: image.height,
      originalWidth: image.width,
      altText: image.altText,
    })
  })

  let availableForSale = false
  variants.forEach((el) => {
    if (el.availableForSale) availableForSale = true
  })

  return (
    <Layout>
      <Helmet>
        <body className="product-page" />

        {/* OpenGraph tags */}
        <meta property="og:image:width" content={firstImage.thumbnail.width} />
        <meta
          property="og:image:height"
          content={firstImage.thumbnail.height}
        />
        <meta property="product:price:amount" content={price} />
        <meta property="product:price:currency" content="USD" />
        {availableForSale ? (
          <meta property="og:availability" content="instock" />
        ) : (
          <meta property="og:availability" content="out of stock" />
        )}

        {/* END OpenGraph tags */}
      </Helmet>

      {firstImage ? (
        <Seo
          type="product"
          title={title}
          description={description}
          image={firstImage.thumbnail.images.fallback.src}
        />
      ) : undefined}

      <div className="max-w-[1200px] mx-auto px-4">
        <h1 className="mb-6 text-2xl font-bold md:text-3xl">{title}</h1>

        {/* Product Layout - Mobile first */}
        <div className="grid grid-cols-1 gap-6 md:grid-cols-12 md:gap-8">
          {/* Image Gallery Section */}
          <div className="product-gallery md:col-span-8">
            {hasImages ? (
              <div className="flex flex-col md:flex-row">
                {/* Thumbnails - Wrapped grid on mobile, Vertical on desktop */}
                <div className="grid order-2 grid-cols-4 gap-2 md:grid-cols-1 md:flex md:flex-col md:order-1 md:mr-4">
                  {galleryImages.map((image, index) => (
                    <button
                      key={index}
                      className={`w-full aspect-square md:w-16  md:h-16 border
                        ${
                          currentThumbIndex === index
                            ? "border-blue-500 border-2"
                            : "border-gray-200 border-1"
                        }`}
                      onClick={() => {
                        setCurrentThumbIndex(index)
                        setFocusAltText(image.altText)
                      }}
                    >
                      <img
                        src={image.thumbnail}
                        alt={image.altText}
                        className="object-cover w-full h-full "
                      />
                    </button>
                  ))}
                </div>

                {/* Main Image */}
                <div className="order-1 mb-4 md:order-2 md:mb-0 md:flex-1">
                  <Gallery>
                    {galleryImages.map((image, index) => (
                      <div
                        key={index}
                        className={
                          index === currentThumbIndex ? "block" : "hidden"
                        }
                      >
                        <Item
                          original={image.original}
                          thumbnail={image.thumbnail}
                          width={image.originalWidth}
                          height={image.originalHeight}
                        >
                          {({ ref, open }) => (
                            <img
                              ref={ref}
                              onClick={open}
                              src={image.thumbnail}
                              alt={image.altText}
                              className="w-full cursor-pointer neu"
                            />
                          )}
                        </Item>
                      </div>
                    ))}
                  </Gallery>
                </div>
              </div>
            ) : (
              <div className="p-12 text-center bg-gray-100 ">
                No Preview Image
              </div>
            )}
          </div>

          {/* Product Details Section */}
          <div className="product-details md:col-span-4">
            <div className="space-y-6">
              {/* Product Description - Shown earlier on mobile */}
              <div className="prose-sm prose md:hidden product-description">
                <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
              </div>

              {/* Price */}
              <div className="text-secondary text-xl font-bold !mt-0 md:text-4xl">
                {formatPrice(
                  priceRangeV2.minVariantPrice.currencyCode,
                  variant.price
                )}
              </div>
              {/* Variant Selector */}
              {hasVariants && (
                <div className="space-y-4">
                  {options.map(({ id, name, values }, index) => (
                    <div key={id}>
                      <Select
                        onValueChange={(value) =>
                          handleOptionChange(index, { target: { value } })
                        }
                      >
                        <SelectTrigger className="w-full h-12 bg-[#ededed]">
                          <SelectValue placeholder={`Select ${name}`} />
                        </SelectTrigger>
                        <SelectContent>
                          {values.map((value) => (
                            <SelectItem key={`${name}-${value}`} value={value}>
                              {value}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  ))}
                </div>
              )}
              {/* Add to Cart Section */}
              <div className="flex gap-4">
                <Select
                  onValueChange={(value) => setQuantity(Number(value))}
                  value={String(quantity)}
                >
                  <SelectTrigger className="w-20 h-12 bg-[#ededed]">
                    <SelectValue placeholder="Select Quantity" />
                  </SelectTrigger>
                  <SelectContent>
                    {[...Array(20).keys()].map((_, index) => {
                      const value = index + 1
                      return (
                        <SelectItem key={value} value={String(value)}>
                          {value}
                        </SelectItem>
                      )
                    })}
                  </SelectContent>
                </Select>
                <AddToCart
                  variantId={productVariant.storefrontId}
                  quantity={quantity}
                  available={available}
                  disabled={!variantSelected}
                />
              </div>

              {/* Social Share */}
              <div>
                <SocialShareLinks
                  title={title}
                  img={firstImage?.thumbnail.images.fallback.src}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Product Description - Hidden on mobile, shown here on desktop */}
        <div className="hidden p-8 mt-12 bg-gray-100 rounded-lg md:block">
          <div className="prose max-w-none product-description">
            <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
          </div>
        </div>

        {/* Related Products */}
        {filteredSuggestions.length > 0 && (
          <div className="pt-8 mt-12 border-t border-gray-200">
            <h3 className="mb-6 text-lg font-medium md:text-xl">
              Related Products
            </h3>
            <div className="grid grid-cols-2 gap-4 md:grid-cols-4 md:gap-6">
              {filteredSuggestions.map((suggestion) => (
                <Link
                  key={suggestion.id}
                  to={suggestion.slug}
                  className="block group"
                >
                  <div className="mb-3 aspect-square">
                    <img
                      src={
                        suggestion.images[0].gatsbyImageData.images.fallback.src
                      }
                      alt={suggestion.title}
                      className="object-cover w-full h-full rounded-md"
                    />
                  </div>
                  <h4 className="text-sm font-medium group-hover:underline">
                    {suggestion.title}
                  </h4>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      description
      descriptionHtml
      productType
      productTypeSlug: gatsbyPath(
        filePath: "/shop/{ShopifyProduct.productType}"
      )
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      variants {
        availableForSale
        compareAtPrice
        id
        price
        selectedOptions {
          value
          name
        }
        storefrontId
        title
      }
      images {
        id
        height
        width
        thumbnail: gatsbyImageData(
          layout: CONSTRAINED
          width: 1920
          aspectRatio: 1
          placeholder: TRACED_SVG
        )
        original: originalSrc
        altText
      }
      options {
        name
        values
        id
      }
    }
    suggestions: allShopifyProduct(limit: 10, filter: { id: { ne: $id } }) {
      nodes {
        tags
        ...ProductCard
      }
    }
  }
`
