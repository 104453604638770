import * as React from "react"
import { StoreContext } from "../context/store-context"
import { Button } from "@/components/ui/button"

export function AddToCart({
  variantId,
  quantity,
  available,
  disabled,
  ...props
}) {
  const { addVariantToCart, loading } = React.useContext(StoreContext)

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(variantId, quantity)
  }

  return (
    <Button
      type="submit"
      onClick={addToCart}
      disabled={!available || loading || disabled}
      className="h-12 md:w-full max-w-[300px] bg-action hover:bg-action/90 self-end"
      {...props}
    >
      {available ? "Add to Cart" : "Out of Stock"}
    </Button>
  )
}
